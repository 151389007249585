import React from 'react'
import './Work.css';
import phone from '../../../images/black-mockup.png'
import Fade from 'react-reveal/Fade'
import Flip from 'react-reveal/Flip'
import Rotate from 'react-reveal/Rotate'
import Zoom from 'react-reveal/Zoom'
import Bounce from 'react-reveal/Bounce'

export default function Work({ work, themeColor, ...props }) {
  return (
    <section className="work">
      <div className="dynamic-image img-fluid d-none d-lg-block d-xl-block" style={{ backgroundColor: themeColor }}></div>
      <div className="dynamic-image-app-second img-fluid d-none d-lg-block d-xl-block" style={{ backgroundColor: themeColor }}></div>
      <div className="dynamic-image-third img-fluid d-none d-lg-block d-xl-block" style={{ backgroundColor: themeColor }}></div>
      <div className="container">
        <Fade bottom duration={2000}>
          <h2 className="text-center main-heading-title mb-0">{work.title.split(',').reduce((all, cur) => [
            ...all,
            <br />,
            cur
          ])}</h2>
          <div className="d-flex justify-content-center">
            <h2 className="line"><span><div className="square" style={{ background: themeColor }}></div></span></h2>
          </div>
        </Fade>
        <div className="row work-done-contain">
          {work.processType.map((p, i) => {
            return (
              <>
                {!(i % 2) ?
                  <div className="col-12 d-md-flex">
                    <div className="col-12 col-md-6 col-lg-6 col-xl-6 d-block d-md-none d-lg-none d-xl-none">
                      <div className="phone-bg d-flex justify-content-center">
                        <Fade bottom>
                          {/*<img src={p.heroImage.fluid.src} className="img-fluid work-phone-img" alt="work-img" />*/}
                        </Fade>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                      <Bounce top duration={2000}>
                        <h2 className="work-title">{p.name.split(',').reduce((all, cur) => [
                          ...all,
                          <br />,
                          cur
                        ])}</h2>
                      </Bounce>
                      <Bounce bottom duration={2000}>
                        <p className="work-description">{p.description.description}</p>
                      </Bounce>
                      <button className="work-btn">Learn More</button>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 col-xl-6 d-none d-md-block d-lg-block d-xl-block">
                      <Fade right>
                        <div className="phone-bg d-flex justify-content-center">
                          <Fade bottom>
                            {/*<img src={p.heroImage.fluid.src} className="img-fluid work-phone-img" alt="work-img" />*/}
                          </Fade>
                        </div>
                      </Fade>
                    </div>
                  </div>
                  :
                  <div className="col-12 d-md-flex">
                    <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                      <Fade left>
                        <div className="phone-bg d-flex justify-content-center">
                          <Fade bottom>
                            {/*<img src={p.heroImage.fluid.src} className="img-fluid work-phone-img" alt="work-img" />*/}
                          </Fade>
                        </div>
                      </Fade>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                      <Bounce top duration={2000}>
                        <h2 className="work-title">{p.name.split(',').reduce((all, cur) => [
                          ...all,
                          <br />,
                          cur
                        ])}</h2>
                      </Bounce>
                      <Bounce bottom duration={2000}>
                        <p className="work-description">{p.description.description}</p>
                      </Bounce>
                      <button className="work-btn">Learn More</button>
                    </div>
                  </div>
                }
              </>
            )
          })}

        </div>
      </div>
    </section>
  )
}
