import React, { useEffect, useState } from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Navbar from "../components/Dashboard/Navbar/Navbar"
import Header from "../components/Dashboard/Header/Header"
import './style.css';
import Service from "../components/AppDevelopment/Service/Service"
import ProjectCount from "../components/ProjectCount/ProjectCount"
import OurProcess from "../components/OurProcess/OurProcess"
import Quote from "../components/Quote/Quote"
import OurRecentWork from "../components/OurRecentWork/OurRecentWork"
import Feedback from "../components/Feedback/Feedback"
import Footer from "../components/Dashboard/Footer/Footer"
import WorkBehind from "../components/AppDevelopment/WorkBehind/WorkBehind"
import Work from "../components/AppDevelopment/Work/Work"
import LessIcon from '../images/expand_less_black_24dp.svg'

export const query = graphql`
    query AppQuery {
   appDevelopment:contentfulAppDevelopment(slug: {eq: "/app-development"}) {
    id
    themeColor
    navbars {
      title
      to
      hRef
    }
     header {
      title
      description {
        description
      }
      actionImage {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      image {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    service {
      title
      ServiceType {
        title
        description {
          description
        }
        serviceImage {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
     otherServices {
      title
      ServiceType {
        title
        number
        description {
          description
        }
        serviceImage {
           gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    doneLotsOfAwesomeWork {
      title
      processType {
        name
        heroImage {
           gatsbyImageData(layout: FULL_WIDTH)
        }
        description {
          description
        }
      }
    }
     footer {
      logo {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      email
      phoneNumber
      footerHeading {
        title
        footerSection {
          slug
          text
        }
      }
      socialLink {
        icon {
           gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
     footerWaveColor {
      firstWaveColor
      secondWaveColor
    }
    copyright {
      title
    }
  }
}
    `

const AppDevelopment = ({ location, data: { appDevelopment } }) => {

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  function scrollHandler() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
  return (
    <>
      <SEO title="Home" />
      <Navbar navbar={appDevelopment.navbars} themeColor={appDevelopment.themeColor} />
      {/* <Header header={appDevelopment.header} /> */}
      {/* <CustomHeader header={appDevelopment.header.reduce((p, c) => ({ ...p, ...c }), {})} /> */}
      <Header header={appDevelopment.header.reduce((p, c) => ({ ...p, ...c }), {})} />
      <Service service={appDevelopment.service.reduce((p, c) => ({ ...p, ...c }), {})} themeColor={appDevelopment.themeColor} />
      <WorkBehind workBehind={appDevelopment.otherServices.reduce((p, c) => ({ ...p, ...c }), {})} themeColor={appDevelopment.themeColor} />
      <Work work={appDevelopment.doneLotsOfAwesomeWork} themeColor={appDevelopment.themeColor} />
      <Footer footer={appDevelopment.footer} copyright={appDevelopment.copyright} />
      {/* <Footer footer={appDevelopment.footer} copyright={appDevelopment.copyright} footerWaveColor={appDevelopment.footerWaveColor} /> */}
      <div className="scroll-button-circle" onClick={scrollHandler} style={{ display: scrollPosition > 1000 ? 'flex' : 'none', background: appDevelopment.themeColor }}>
        <img src={LessIcon} alt="lessIcon" className="mb-0 less-icon" />
      </div>
    </>
  )
}

export default AppDevelopment
