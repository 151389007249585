import React from 'react'
import './Service.css'
import Fade from 'react-reveal/Fade';
import Spin from 'react-reveal/Spin';

export default function Service({ service, themeColor, ...props }) {
  return (
    <section className="app-service">
      {/* <img src={dots} alt="dots" className="dots-img img-fluid d-none d-lg-block d-xl-block" />
      <img src={dots} alt="dots" className="dots-img-second img-fluid d-none d-lg-block d-xl-block" /> */}
      <div className="dynamic-image img-fluid d-none d-lg-block d-xl-block" style={{ backgroundColor: themeColor }}></div>
      <div className="dynamic-image-app-second img-fluid d-none d-lg-block d-xl-block" style={{ backgroundColor: themeColor }}></div>
      <div className="dynamic-image-third img-fluid d-none d-lg-block d-xl-block" style={{ backgroundColor: themeColor }}></div>
      <div className="container">
        <div className="service-box">
          <Fade bottom duration={3000}>
            <h2 className="text-center main-heading-title mb-0">{service.title}</h2>
            <div className="d-flex justify-content-center">
              <h2 className="line"><span><div className="square" style={{ background: themeColor }}></div></span></h2>
            </div>
          </Fade>
        </div>
        <div className="row service-card">
          {service.ServiceType.map((p, i) => (
            !(i % 2) ?
              <div className="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 mt-5 mt-sm-0">
                <div className="card">
                  <div className="align-items-center card-body d-flex flex-column">
                    <div className="app-circle d-flex justify-content-center align-items-center">
                      <Spin duration={1500}>
                        {/*<img src={p?.serviceImage?.fluid?.src} className="mb-0 img-fluid service-card-icon" alt="service-icon" />*/}
                      </Spin>
                    </div>
                    <Fade top duration={1000}>
                      <h2 className="my-3 service-card-title text-center">{p.title}</h2>
                    </Fade>
                    <Fade bottom duration={1000}>
                      <p className="text-center service-card-description">{p.description.description}</p>
                    </Fade>
                  </div>
                </div>
              </div>
              :
              <div className="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 mt-5">
                <div className="card">
                  <div className="align-items-center card-body d-flex flex-column">
                    <div className="app-circle d-flex justify-content-center align-items-center">
                      <Spin duration={500}>
                        {/*<img src={p?.serviceImage?.fluid?.src} className="mb-0 img-fluid service-card-icon" alt="service-icon" />*/}
                      </Spin>
                    </div>
                    <Fade top duration={1000}>
                      <h2 className="my-3 service-card-title text-center">{p.title}</h2>
                    </Fade>
                    <Fade bottom duration={1000}>
                      <p className="text-center service-card-description">{p.description.description}</p>
                    </Fade>
                  </div>
                </div>
              </div>
          ))}
        </div>
      </div>
    </section>
  )
}
