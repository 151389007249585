import React from 'react'
import './WorkBehind.css'
import round from '../../../images/Layer -1.png'
import round1 from '../../../images/Layer 3.png'
import Service from '../Service/Service';
import Fade from 'react-reveal/Fade'
import Flip from 'react-reveal/Flip'
import Rotate from 'react-reveal/Rotate'
import Zoom from 'react-reveal/Zoom'

export default function WorkBehind({ workBehind, themeColor, ...props }) {
  return (
    <section className="step-process">
      <div className="container ">
        <Fade bottom duration={3000}>
          <h2 className="text-center main-heading-title mb-0">{workBehind.title.split(',').reduce((all, cur) => [
            ...all,
            <br />,
            cur
          ])}</h2>
          <div className="d-flex justify-content-center">
            <h2 className="line"><span><div className="square" style={{ background: themeColor }}></div></span></h2>
          </div>
        </Fade>
        {workBehind.ServiceType.map((p, i) => (
          !(i % 2) ?
            <div className="row row-gap">
              <Rotate top left>
                <img src={round} alt="round-image" className="mb-0 step-round-shape-img-second img-fluid d-none d-xl-block" />
              </Rotate>
              <div className="col-4 d-flex align-items-center step-card">
                <div className="card">
                  <div className="card-body">
                    <Rotate top right duration={2000}>
                      <h2 className="step-number mb-0">{p.number}</h2>
                    </Rotate>
                    <Flip bottom duration={2000}>
                      <h3 className="step-title mb-0">{p.title}</h3>
                    </Flip>
                    <Fade bottom duration={2000}>
                      <p className="step-description">{p.description.description}</p>
                    </Fade>
                    <span className="step-description-more">Learn More</span>
                  </div>
                </div>
              </div>
              <div className="col-8">
                <Zoom>
                  {/*<img src={p.serviceImage.fluid.src} className="step-img " alt="step-img" />*/}
                </Zoom>
              </div>
            </div>
            :
            <div className="row row-gap">
              <Rotate top left>
                <img src={round1} alt="round-image" className="step-round-shape-img img-fluid d-none d-xl-block" />
              </Rotate>

              <div className="col-8">
                <Zoom>
                  {/*<img src={p.serviceImage.fluid.src} className="step-img " alt="step-img" />*/}
                </Zoom>
              </div>
              <div className="col-4 d-flex align-items-center second-step-card">
                <div className="card">
                  <div className="card-body">
                    <Rotate top right duration={2000}>
                      <h2 className="step-number mb-0">{p.number}</h2>
                    </Rotate>
                    <Flip bottom duration={2000}>
                      <h3 className="step-title mb-0">{p.title}</h3>
                    </Flip>
                    <Fade bottom duration={2000}>
                      <p className="step-description">{p.description.description}</p>
                    </Fade>
                    <span className="step-description-more">Learn More</span>
                  </div>
                </div>
              </div>
            </div>
        ))
        }
      </div>
    </section>
  )
}
